<template>
    <div class="container-fluid p-4">
        <div class="row row-cols-1 row-cols-lg-2">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Display</div>
                    <div class="card-body">
                        <h2 class="display-1">Display 1</h2>
                        <h2 class="display-2">Display 2</h2>
                        <h2 class="display-3">Display 3</h2>
                        <h2 class="display-4">Display 4</h2>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Headings</div>
                    <div class="card-body mb-4">
                        <h1>H.1 Heading</h1>
                        <h2>H.2 Heading</h2>
                        <h3>H.3 Heading</h3>
                        <h4>H.4 Heading</h4>
                        <h5>H.5 Heading</h5>
                        <h6>H.6 Heading</h6>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Text variations</div>
                    <div class="card-body">
                        <div class="lead mb-2">Lead Text</div>

                        <header class="mb-2">Field Header</header>

                        <div class="mb-2">Default Font Size</div>

                        <div class="text-secondary mb-2">Placeholder Text</div>

                        <div class="text-muted mb-2">Disabled Text</div>

                        <div class="mb-2">
                            <mark>Highlighted Text</mark>
                        </div>

                        <div class="mb-2">
                            <span class="font-14">Small Font size</span>
                        </div>

                       <div class="mb-2">
                           <button class="btn btn-dark btn-sm rounded-0">Small Button Font</button>
                       </div>

                        <div class="mb-2">
                            <button class="btn btn-dark rounded-0">Normal Button Font</button>
                        </div>

                        <div class="mb-2">
                            <button class="btn btn-dark btn-lg rounded-0">Large Button Font</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Body Text</div>
                    <div class="card-body">
                        <p>
                            You can use the mark tag to <mark>highlight text.</mark>
                        </p>
                        <p>
                            <s>This line of text is meant to be treated as deleted</s>
                        </p>
                        <p>
                            <u>This line of text will render as underlined.</u>
                        </p>
                        <p>
                            <small class="text-secondary">This line of text is meant to be treated as fine print.</small>
                        </p>
                        <p>The following snippet of text is <strong>rendered as bold text.</strong></p>
                        <p>The following snippet of text is <em>rendered as italicized text.</em></p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Lead Text</div>
                    <div class="card-body">
                        <p class="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Body text</div>
                    <div class="card-body">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Small text</div>
                    <div class="card-body">
                        <small>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</small>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Tiny text</div>
                    <div class="card-body">
                        <p class="font-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </div>

        </div>

        <div class="row row-cols-1">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Text Alignment</div>
                    <div class="card-body">
                        <div class="row mb-4">
                            <div class="col-md-4">
                                <p class="text-left font-weight-semibold">Left Align</p>
                                <p class="text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                            <div class="col-md-4">
                                <p class="text-center font-weight-semibold">Center Align</p>
                                <p class="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                            <div class="col-md-4">
                                <p class="text-right font-weight-semibold">Right Align</p>
                                <p class="text-right">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Block Quotes</div>
                    <div class="card-body">
                        <blockquote class="blockquote">
                            <div style="max-width: 875px">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et</div>
                            <footer class="blockquote-footer">Someone famous in
                                <cite title="Source Title">Source Title</cite>
                            </footer>
                        </blockquote>

                        <blockquote class="blockquote text-right">
                            <div class="ml-auto text-left" style="max-width: 875px">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et</div>
                            <footer class="blockquote-footer">Someone famous in
                                <cite title="Source Title">Source Title</cite>
                            </footer>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Typography',
}
</script>
